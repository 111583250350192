<template>
  <div class="">
    <div class="">
      <div class="page-banner  pb-3 ">
         <img :src="this.getSiteProps('clubinnerpages.headerbanner')" style="width:100%;" alt="page-banner">
         <!-- <div class="page-title pb-5">
          <h5 class="text-white">FAQ</h5>
        </div> -->
      </div>
      <div class="row pb-3">
        <div class="col-md-6 faq-page mb-5">
            <!-- <p>{{this.clubFaqitem.title}}</p> -->
          <div class="accordion" role="tablist">
             <div v-for="(item1, indexparent) in clubFaqitemone" :key="indexparent" class="accordion" role="tablist">      
            <h3 class="faq-title">{{item1.title}}</h3>
            
           <b-card
              no-body
              class="mb-1"
              v-for="(secondcols, indexchild) in item1.items"
              :key="'types' + indexchild"
            >          
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="'accordion-' +indexchild +indexparent"
                  variant="info"
                  >{{ secondcols.name }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + indexchild+indexparent"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="secondcols.desc"></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          </div>
        </div>
        <div class="col-md-6 faq-page mb-5">
          <div v-for="(clubitems, indexparent) of clubFaqitemtwo" :key="indexparent" class="accordion" role="tablist">     
          <div class="accordion" role="tablist">
            <h3 class="faq-title">{{clubitems.title}}</h3>
            <b-card
              no-body
              class="mb-1"
              v-for="(clubiteminner, indexchild) in clubitems.items"
              :key="'types' + indexchild"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle="'accordion-dp-' + indexchild+indexparent"
                  variant="info"
                  >{{ clubiteminner.name }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-dp-' + indexchild+indexparent"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="clubiteminner.desc"></b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faq",
  components: {},

  data() {
    return {
      clubFaqitemone: null,
      clubFaqitemtwo :null
    };
  },
  methods : {     
  },
    mounted() {
      const retry = setInterval(
        () => { 
            this.clubFaqitemone = this.getSiteProps("clubfaq.col1");           
            this.clubFaqitemtwo = this.getSiteProps("clubfaq.col2");
            if(this.clubFaqitemone && this.clubFaqitemtwo) clearInterval(retry);
        },
        200
      ) 
    }

};
</script>
